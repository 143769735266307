:root {
  --T1: #252529;
  --T2: #45454D;
  --T3: #848490;
  --T4: #BBBBC5;
  --T2-dark: rgba(255, 255, 255, 0.7);
  
  /* 品牌色 */
  --1: #821062;
  /* 主辅助色 */
  --2: #F15802;

  --BG: #F5F5FA;
  --image: #E0E4EE;
}
